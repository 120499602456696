#root {
    height: 100%;
}

.margin-auto {
    margin: auto;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.text-success {
    color: green !important;
}

.text-danger {
    color: #AE2E24 !important;
}

.text-right {
    text-align: right;
}

.text-center {
    text-align: center;
}

.flex-wrap {
    flex-wrap: wrap;
}

.text-uppercase {
    text-transform: uppercase;
}

.flex-column {
    flex-direction: column;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-start {
    justify-content: start;
}

.justify-content-end {
    justify-content: end;
}

.justify-content-between {
    justify-content: space-between;
}

.justify-content-stretch {
    justify-content: stretch;
}

.align-items-start {
    align-items: start;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-items-center {
    align-items: center;
}

.align-items-stretch {
    align-items: stretch;
}

.align-items-end {
    align-items: end;
}

.align-items-base {
    align-items: baseline;
}

.gap-0 {
    gap: 0 !important;
}
.gap-025 {
    gap: .25rem !important;
}
.gap-05 {
    gap: .5rem !important;
}

.gap-1 {
    gap: 1rem !important;
}

.cursor-pointer {
    cursor: pointer;
}

[data-testid="PopupModal"] {
    max-width: calc(100% - 2rem) !important;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.no-margin {
    margin: 0 !important;
}
.no-padding {
    padding: 0 !important;
}

.text-italic {
    font-style: italic;
}
